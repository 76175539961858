const stories = (state = [], action) => {
    switch (action.type) {
      case 'ADD_STORY':
        return [
          ...state,
          action
        ]
      case 'TOGGLE_STORY':
        return state.map(story =>
          (story.id === action.id)
            ? {...story, completed: !story.completed}
            : story
        )
      default:
        return state
    }
  }
  
  export default stories
  