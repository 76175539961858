import React from 'react';
import './brick.css';
import { SocialbeatService } from '../../services/socialbeat';

class Brick extends React.Component {

  socialbeatService = new SocialbeatService();

  constructor(props) {
    super(props);
    // Assign the brick reference to wallBrick
    this.props.wallBrick.brick = this;

    //console.log('l', props.wall.state.stories.length);
    console.log('props.index', props.index);
    this.state = {
      index: props.index,
      wall: props.wall,
      story: props.story,
      rand: 10,
      style: 'true',
      platforms: {}
    };


    //console.log(this.state.story.color);
    //console.log('brick.stories', this.state.wall.state.stories);
    this.stories = this.state.wall.state.stories;
    this.references = this.state.wall.references;

    this.onTypingDoneStory = this.onTypingDoneStory.bind(this);
    this.onTypingDoneSite = this.onTypingDoneSite.bind(this);
    this.openUrl = this.openUrl.bind(this);
    

  }

  /**
   * Restart after a story change
   * @param {*} options 
   */
  restart(options){
    //const story = this.state.story;
    const story = options.story;
    story.firstTime = options.firstTime;
    // Set the story to the brick
    //this.setState({ story: options.story, style:'true'});
    this.setState({ story: story, style:'true'});
    // Restart the typer
    this.typer.restart(options);

  }


  shortNumber(number: number, fractionSize: number) {


    if (number === null) return null;
    if (number === 0) return "0";

    if (!fractionSize || fractionSize < 0)
      fractionSize = 1;

    let abs = Math.abs(number);
    let rounder = Math.pow(10, fractionSize);
    let isNegative = number < 0;
    let key = '';
    let powers = [
      { key: "Q", value: Math.pow(10, 15) },
      { key: "T", value: Math.pow(10, 12) },
      { key: "B", value: Math.pow(10, 9) },
      { key: "M", value: Math.pow(10, 6) },
      { key: "K", value: 1000 }
    ];

    for (let i = 0; i < powers.length; i++) {

      let reduced = abs / powers[i].value;

      reduced = Math.round(reduced * rounder) / rounder;

      if (reduced >= 1) {
        abs = reduced;
        key = powers[i].key;
        break;
      }
    }

    return (isNegative ? '-' : '') + abs + key;
  };

  openUrl(e, url) {
    console.log('open', this.state.wall.state.brickUpdaterStatus);
    if(this.state.wall.state.fromStop) return;
    console.log('url', url)
    e.preventDefault();
    window.open(url, "_blank");

  }
  getTimeAgo(date) {
    const diff = new Date().getTime() - new Date(date).getTime();
    const t = parseInt((Math.abs(diff) / 1000).toFixed(0), 0);

    let r = t + "s";

    if (t < 60) {
      r = "now";
    }
    else if (t < 3600) {
      r = Number(t / 60).toFixed(0) + "m";
    } else if (t < 7200 * 24) {
      r = Number(t / 3600).toFixed(0) + "h";
    }

    else {
      r = Number(t / 3600 / 24).toFixed(0) + "d";
    }
    return r;
  }

  onTypingDoneSite(e) {
    if (!this.references[this.state.index]['site']) {
      this.references[this.state.index]['site'] = e;
    }
  }


  onTypingDoneStory(e) {
    if (!this.references[this.state.index]['story']) {
      this.references[this.state.index]['story'] = e;
    }
    this.setState({ style: 'true' });
    this.props.wall.onAddBrick({ brick: this, typer: e, index:this.props.index });
  }

  hexToRgb(hex) {
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? [
      parseInt(result[1], 16),
      parseInt(result[2], 16),
      parseInt(result[3], 16),
      1
     ] : null;
  }
  componentWillMount(){
    this.setState({height: window.innerHeight, width: window.innerWidth});
  }
  render() {
    return <div></div>
  }
}
export default Brick;

