import React from 'react';
import './filter-selector.css';
import { Dropdown } from 'semantic-ui-react';

class FilterSelector extends React.Component {
  
  constructor(props) {
    super(props);
    this.handleFilterChange = this.handleFilterChange.bind(this);
    
    let filters = [];
    let createFilters = [];
    // If filtersAvaiable for firstChannel
    // Use it
    if (props.settings.filtersAvaiable.channels[props.dashboard.state.startChannelId]){
      props.settings.filtersAvaiable.channels[props.dashboard.state.startChannelId].list.forEach(item =>createFilters.push(props.settings.channelsAvaiable.list.filter(elem => elem.id===item.id)[0]));
    }else{
      // Else use default filters
      createFilters = props.settings.channelsAvaiable.list.filter(item=>item.metadata.inFilter);
    }

    // Get current channelid by url
    const channelUrlId = props.dashboard.getChannelIdByUrl();
    // Default item selected is the first one
    let itemId = 0;
    // Create filters
    createFilters.forEach((element, index) => {
      element.index = index;
      filters.push({
        key: element.id,
        text: element.metadata.title,
        value: element
      })

      // If is current
      if(channelUrlId && element.id===channelUrlId){
        itemId = index;
      }
    });

    this.state ={
      filters: filters,
      selectedIndex:itemId,
      value: filters[itemId].value
    }  

  }
  handleFilterChange(e, {value, index}){
    console.log('handleFilterChange', value.index); 
    console.log('handleFilterChange selectedIndex', this.state.selectedIndex);
    this.props.dashboard.changeWall(value, index);
    console.log('valuex', value);
    this.setState({value: value});

  }

  render() {
    const { value } = this.state;
    console.log('value', this.state.value);
    console.log('this.props.walls', this.props.walls);
    const wallSettings = this.props.walls[this.props.selectedWallIndex];
    console.log('this.props.walls', this.props.walls);
    return (
      <div>
        {this.props.walls.length >0 &&
        <div>
        Le storie
                            {wallSettings.metadata.platforms.map((value, index) => {
          return (
            <span  key={value}> ({value}) </span>
          )
        })}
        {value.metadata.prefix} <span className="selected"><Dropdown value={value}  onChange={this.handleFilterChange} inline  options={this.state.filters} /></span> {value.metadata.prefixPost}
                                </div>   
                             }      
                                   
                                    </div>
    )
  }
}
export default FilterSelector;