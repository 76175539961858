import BrickTemplate from './templates/template'
import BrickTemplate2 from './templates/template2'
import { get } from 'https';

class BrickFabric {
 static templates = {
             BrickTemplate,
             BrickTemplate2
            }


 static get(type){
            return BrickFabric.templates[type];
 }
}export default BrickFabric;