let nextTodoId = 0
export const addStory2 = text => ({
  type: 'ADD_STORY',
  id: nextTodoId++,
  text
})

export const addStory = story => ({
  type: 'ADD_STORY',
  story: story
})


export const setVisibilityFilter = filter => ({
  type: 'SET_VISIBILITY_FILTER',
  filter
})

export const toggleTodo = id => ({
  type: 'TOGGLE_TODO',
  id
})

export const VisibilityFilters = {
  SHOW_ALL: 'SHOW_ALL',
  SHOW_COMPLETED: 'SHOW_COMPLETED',
  SHOW_ACTIVE: 'SHOW_ACTIVE'
}
