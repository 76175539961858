import React from 'react';
import './matrix-selector.css';
import Tracker from '../../services/tracker.js';

import Icon from 'react-icons-kit';
import { threeUp } from 'react-icons-kit/iconic/threeUp'

class MatrixSelector extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      tableMatrixSelector: this.createTableMatrix()
    }
  }
  onCellClick(e, x, j) {
    e.preventDefault();
    // Set the pointer to first story
    this.setState({ 'lastStoryIndex': 0 });
    Tracker.event({
      category: 'UI',
      action: 'changeWallMatrix',
      label: x + ',' + j,
      value: x * j
    });
    // Change matrix
    this.props.dashboard.changeWallMatrix(x, j);
  }

  onCellHover(e, row, col) {
    e.preventDefault();
    this.setState({ tableMatrixSelector: this.createTableMatrix({ hRow: row, hCol: col }) });
  }
  onTableOut(e) {
    e.preventDefault();
    this.setState({ tableMatrixSelector: this.createTableMatrix({ hRow: -1, hCol: -1 }), visible: false });
  }

  onIconTableHover(e) {
    e.preventDefault();
    this.setState({ visible: true });
  }

  createTableMatrix = (options = {}) => {
    const wallSettings = this.props.walls[this.props.selectedWallIndex];
    let table = [];
    for (let x = 0; x < wallSettings.stories.matrix.maxRows; x++) {
      let children = [];
      for (let j = 0; j < wallSettings.stories.matrix.maxCols; j++) {
        children.push(<td onClick={e => this.onCellClick(e, x, j)} key={'zzz' + x +'_' + j} onTouchStart={e => this.onCellHover(e, x, j)} onMouseOver={e => this.onCellHover(e, x, j)} className={"table-cell-selector hover-" + (x <= options.hRow && j <= options.hCol) + " selected-" + (x < wallSettings.stories.matrix.rows && j < wallSettings.stories.matrix.cols)}></td>);
      }
      table.push(<tr key={'axxxx' + x}>{children}</tr>);
    }
    return table
  }

  render() {

    return (
      <div className="matrix-selector">
        {!this.state.visible &&
          <Icon onTouchStart={e => this.onIconTableHover(e)} onMouseOver={e => this.onIconTableHover(e)} size={32} icon={threeUp} className="icon-table" />
        }
        {this.state.visible &&
          <table onMouseLeave={e => this.onTableOut(e)}>
            <tbody>
              {this.state.tableMatrixSelector}
            </tbody>
          </table>
        }
      </div>
    )
  }
}
export default MatrixSelector;