import React from 'react';
import './template.css';
import Brick from '../brick';

//import Typist from 'react-typist';
import Typist from '../../typist/Typist'

import Icon from 'react-icons-kit';
import { shareAlt } from 'react-icons-kit/fa/shareAlt';
import { thumbsOUp } from 'react-icons-kit/fa/thumbsOUp';
import { comments } from 'react-icons-kit/fa/comments';
import { heart } from 'react-icons-kit/fa/heart';
import { ic_multiline_chart } from 'react-icons-kit/md/ic_multiline_chart';

import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile
} from "react-device-detect";

var QRCode = require('qrcode.react');

class BrickTemplate extends Brick {

  kFormatter(num) {
    return Math.abs(num) > 999 ? Math.sign(num)*((Math.abs(num)/1000).toFixed(1)) + 'k' : Math.sign(num)*Math.abs(num)
}
    

  render() {
    //console.log('wall story template', this.state.story);
    let typing = "";
    // Background selection on typewriting
    let background = !this.props.wall.props.wallSettings.typers.changeColorOnTyping.enabled || this.state.story.story.firstTime === "true" || this.state.style === 'false' ? this.state.story.story.color.default : this.props.wall.props.wallSettings.typers.changeColorOnTyping.color;
    let backgroundColor = background;
    //const border = !this.props.wall.props.wallSettings.typers.changeColorOnTyping.enabled || this.state.story.story.firstTime === "true" || this.state.style === 'false' ? this.state.story.story.color.default : this.props.wall.props.wallSettings.typers.changeColorOnTyping.color;
    let backgroundImage = 'url(\'' + this.state.story.story.imageUrl + '\') ' + background;


    const width = window.innerWidth;
    const height = window.innerHeight;
    const guidelineBaseWidth = 350;
    const guidelineBaseHeight = 680;

    const scale = size => width / guidelineBaseWidth * size;
    const verticalScale = size => height / guidelineBaseHeight * size;
    const moderateScale = (size, factor = 0.5) => size + (scale(size) - size) * factor;


    //const fact = this.props.wall.props.wallSettings.stories.matrix.tot === 1 ? 0.5 : 0.5;
    const fact = 1;

    let fontSize = this.props.wall.props.wallSettings.stories.template.settings.fontSize || ((window.innerWidth * window.innerHeight * 100) / 2000000 / (this.props.wall.props.wallSettings.stories.matrix.cols * fact));
    fontSize = this.props.wall.props.wallSettings.stories.template.settings.fontSize || moderateScale(20);
    let fontSizeInt = fontSize / this.props.wall.props.wallSettings.stories.matrix.cols;
    //const marginLeft = parseInt(fontSize / 5 / this.props.wall.props.wallSettings.stories.matrix.cols);

    fontSize = (fontSize / this.props.wall.props.wallSettings.stories.matrix.cols) + 'px';

    let marginLeft = this.props.wall.props.wallSettings.stories.template.settings.title.marginLeft;
    if (isMobile) { marginLeft = this.props.wall.props.wallSettings.stories.template.settings.title.marginLeftMobile }
    const typingWrapperStyle = {
      fontSize: fontSize,
      marginLeft: marginLeft,
      marginRight: this.props.wall.props.wallSettings.stories.template.settings.title.marginRight,
      borderLeft: 'solid 1px ' + backgroundColor,
      paddingBottom: this.props.wall.props.wallSettings.stories.template.settings.title.paddingBottom
    }

    const multiplier = (fontSize > 20) ? 30 : 20;
    const lineHeight = parseInt(fontSize + (fontSize / multiplier), 10);
    const finalLineHeight = this.props.wall.props.wallSettings.stories.template.settings.title.lineHeight + (this.props.wall.props.wallSettings.stories.matrix.tot / 20);

    let styleTyper = {
      'boxShadow': '0 0 0 6px ' + backgroundColor,
      'background': backgroundColor,
      'lineHeight': finalLineHeight,
      //'lineHeight': 3 - fontSizeInt / 30 * (Math.log2(fontSizeInt / 15))

    }
    let fontSizeCategory = (fontSizeInt * 0.6) + 'px';
    const categoryStyle = {
      'fontSize': fontSizeCategory,
      'color': this.state.story.story.color.default,
      'marginBottom': '5px',
      'fontShadow': null
      //'lineHeight': finalLineHeight*0.5,
    }


    let backgroundText;
    let rgb = this.hexToRgb(this.state.story.story.color.negative);
    let rgbDefault = this.hexToRgb(this.state.story.story.color.default);
    let rgba80 = 'rgba(' + rgbDefault[0] + ',' + rgbDefault[1] + ',' + rgbDefault[2] + ',0.6)';
    if (this.props.wall.props.wallSettings.stories.template.settings.image.type === 'background') {

      backgroundText = 'linear-gradient(to top,rgba(' + rgb[0] + ',' + rgb[1] + ',' + rgb[2] + ',0.4) 80%,rgba(0,0,0,0))';
      backgroundText = 'linear-gradient(to top,rgba(' + rgb[0] + ',' + rgb[1] + ',' + rgb[2] + ',0.4) 100%,rgba(0,0,0,0))';
      //backgroundText = 'linear-gradient(to top,rgba(0,0,0,0.4) 100%,rgba(0,0,0,0))';
      background = backgroundImage;
    }
    if (this.state.story.story.title) {
      typing = <Typist onTypingDone={this.onTypingDoneStory} className={'typing-' + this.state.style} brick={this} wall={this.props.wall} wallBrick={this.props.wallBrick}><span className="full-typing" ><span className="my-custom-class" style={styleTyper}> {this.state.story.story.title} </span></span></Typist>;
    }

    const backgroundImageStyle = {
      background: backgroundText,
      verticalAlign: this.props.wall.props.wallSettings.stories.template.settings.title.verticalAlign,

    }

    return (
      <div  onMouseEnter={(e) => { this.props.wall.brickMouseOver = this }} onClick={(e) => this.openUrl(e, this.state.story.story.url)} className={'cursor-pointer  wrapper-container rotation-' + this.state.style + ' first-time-' + this.state.story.story.firstTime} style={{
        background:this.state.story.story.color.default, height: this.props.wall.props.wallSettings.stories.matrix.rowsPerc + '%',
        'fontSize': fontSize,
        width: this.props.wall.props.wallSettings.stories.matrix.colsPerc + '%'


      }}>
        <div className={'wrapper-container selected-avaiable-tension-'+this.props.wall.state.tension+' selected-avaiable-' + (this.props.wall.state.selectedAvaiable === this.props.index) + ' selected-avaiable-direction-' + (this.props.wall.state.selectedAvaiableDirection) } style={{background: background, height: '100%',width: '100%'}}>
          {this.props.wall.props.wallSettings.stories.template.settings.image.effect === 'tv' &&
            <div>
              <span className="screen"></span>
              <span className="top"></span>
              <span className="bottom"></span>
            </div>
          }

          <div className="header"><div style={{ display: 'table-cell', 'verticalAlign': 'middle' }}><img src={this.state.story.story.siteIcon} width='30px' style={{ marginRight: '5px', opacity: 0.7, borderRadius: '100%' }}></img></div><div style={{ display: 'table-cell', 'verticalAlign': 'middle' }}>{this.state.story.story.site}</div></div>
          {this.props.wall.props.wallSettings.stories.template.settings.image.type === 'top' &&
            <div className={'top-image'} style={{ background: backgroundImage, height: '50%' }}>

            </div>
          }
          {this.props.wall.props.wallSettings.stories.template.settings.image.type === 'background' &&
            <div style={{ height: '60%' }}>

            </div>
          }
          <div className={'background-image'} style={backgroundImageStyle}>

            <div className={'typing-wrapper-container'} style={typingWrapperStyle}>

              {this.state.story.story.places && <div className="category" style={categoryStyle}>{this.state.story.story.places.length > 0 && <span style={{ marginLeft: '-5px', display: 'inline-block', background: rgba80, borderRadius: '3px', padding: '4px', marginRight: '5px', color: 'rgba(255,255,255,0.8)' }}>{this.state.story.story.places[0].city.name}</span>}{this.state.story.story.classification && this.state.story.story.classification.predictions[0].label}</div>}

              {typing}


            </div>

            <div className="interactions" style={{ color: this.state.story.story.color.default }}>
              {true &&
                <div className="reaction semitransparent"
                ><span>
                    <Icon icon={thumbsOUp} />
                  </span>
                  <div>{this.kFormatter(this.state.story.story.beats)}</div>
                </div>}
              {false &&
                <div className="reaction semitransparent"
                ><span>
                    <Icon icon={thumbsOUp} />
                  </span>
                  <div>{this.state.story.story.reaction}</div>
                </div>}
              {false &&
                <div className="comment semitransparent"
                ><span>
                    <Icon icon={comments} />
                  </span>
                  <div>{this.state.story.story.comment}</div>
                </div>}
              {false &&
                <div className="share semitransparent"
                ><span>
                    <Icon icon={shareAlt} />
                  </span>
                  <div>{this.state.story.story.share}</div>
                </div>}
              <div className="beats semitransparent"
              ><span>
                  <Icon icon={ic_multiline_chart} />
                </span>
                <div>{this.state.story.story.id + 1}°</div>
              </div>
              {this.props.wall.props.wallSettings.stories.reactions.beats.enabled && this.state.story.story.beats > 0 &&
                <div className="beats semitransparent"
                ><span>[{this.props.wall.props.wallSettings.stories.reactions.beats.enabled}]
                  <Icon icon={heart} />
                  </span>
                  <div>{this.shortNumber(this.state.story.story.beats)}</div>
                </div>
              }



            </div>
            {this.props.wall.props.wallSettings.qr.enabled &&
              <div className="qr-container" style={{ color: this.state.story.story.color.default, fontSize: this.props.wall.props.wallSettings.qr.size / 10 * 2 }}>{this.state.story.story.storyId.substr(0, 6)}
                <div className="qr" style={{ background: this.state.story.story.color.default, width: this.props.wall.props.wallSettings.qr.size + 10, height: this.props.wall.props.wallSettings.qr.size + 10, padding: '5px' }}>

                  <QRCode value={'http://sobeat.me/' + this.state.story.story.storyId.substr(0, 6)} size={this.props.wall.props.wallSettings.qr.size} fgColor="#000" bgColor={this.state.story.story.color.default} />
                </div>
              </div>
            }
            {this.props.wall.props.wallSettings.stories.template.settings.dateFormat== 'timeAgo' &&
            <span className="time semitransparent" style={{ color: this.state.story.story.color.default }}>{this.getTimeAgo(this.state.story.story.dateCreated)}</span>
            }
            {this.props.wall.props.wallSettings.stories.template.settings.dateFormat == 'standard' &&
            <span className="time semitransparent standard" style={{ color: this.state.story.story.color.default }}>{new Intl.DateTimeFormat('it-IT', { year: 'numeric', month: 'long', day: '2-digit' }).format(new Date(this.state.story.story.dateCreated))}</span>
          }
            <div className="container-right">
              <div className="container-platforms">
                {this.state.platforms && this.state.platforms.rai && this.state.platforms.rai.videos.map((video, index) => {
                  return (
                    <div onClick={(e) => this.openUrl(e, video.source.playable_url)} >


                      <div className="platform-content-image" style={{ background: 'url(\'' + video.thumbnails.default.url + '\') ' + this.state.story.story.color.default, height: '100px', width: '120px' }}>
                        {video.title}
                      </div>

                    </div>
                  )

                })}
              </div>

            </div>
          </div>

        </div>
      </div>
    )
  }
}
export default BrickTemplate;