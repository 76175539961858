

import React from 'react';
import './App.css';
import Dashboard from './components/dashboard/dashboard.js'
import { BrowserRouter as Router } from "react-router-dom";
import Tracker from './services/tracker.js';

function App() {
  Tracker.init();
  return (
      <Router>
        <Dashboard></Dashboard>
      </Router>
  );


}
export default App;
