import axios from 'axios';
import User from './user.js'

class Settings  {

    broker = { url: 'https://api3.socialbeat.it/broker/api/v3/' };
    auth = { url: 'http://localhost:8000/api/v4/' };
    settings = {url: process.env.PUBLIC_URL + '/data/settings.json'}
    user = new User(process.env.PUBLIC_URL + '/data/actuator.json');
    selectedDashboard = 0;
    dashboards = []
    wall = null;
    defaultWallStoriesRepeat = 0;
    tracker = {
      google: {
        id: 'UA-111628409-1',
        options:{
          debug: false,
          titleCase: false,
          gaOptions: {
            userId: this.user.hash
          }
        }
      }
    }

    api = {
          socialbeat: {
              actuator: {
                  autoUpdate: {time: 60000},
                  params: [
                    {
                      "params": {
                        "filter": {
                          "query": "*"
                        },
                        "from": 0,
                        "size": 100,
                        "sort": {
                            "entity": "rank.beats",
                            "gravity": 0.3
                          },
                        "filters": [
                          {
                            "filter": "it",
                            "entity": "country.id",
                            "enabled": true,
                            "type": "term",
                            "boolean": "should"
                          },
                          {
                            "filter": "news",
                            "entity": "platform.name",
                            "enabled": true,
                            "type": "term",
                            "boolean": "should"
                          },
                          {
                            "filter": "facebook",
                            "entity": "platform.name",
                            "enabled": false,
                            "type": "term",
                            "boolean": "should"
                          },
                          {
                            "filter": {
                              "gte": "now/d",
                              "lte": "now-0h"
                            },
                            "entity": "created",
                            "enabled": true,
                            "type": "range",
                            "boolean": "must"
                          },
                          {
                            "filter": "us",
                            "entity": "country.id",
                            "enabled": false,
                            "type": "term",
                            "boolean": "should"
                          },
                          {
                            "filter": "ch",
                            "entity": "country.id",
                            "enabled": false,
                            "type": "term",
                            "boolean": "should"
                          },
                          {
                            "filter": "uk",
                            "entity": "country.id",
                            "enabled": false,
                            "type": "term",
                            "boolean": "should"
                          },
                          {
                            "filter": "fr",
                            "entity": "country.id",
                            "enabled": false,
                            "type": "term",
                            "boolean": "should"
                          },
                          {
                            "filter": "de",
                            "entity": "country.id",
                            "enabled": false,
                            "type": "term",
                            "boolean": "should"
                          },
                          {
                            "filter": "br",
                            "entity": "country.id",
                            "enabled": false,
                            "type": "term",
                            "boolean": "should"
                          },
                          {
                            "filter": "video",
                            "entity": "platform.name",
                            "enabled": false,
                            "type": "term",
                            "boolean": "should"
                          },
                          {
                            "filter": "ingv",
                            "entity": "platform.name",
                            "enabled": false,
                            "type": "term",
                            "boolean": "should"
                          },
                          {
                            "filter": "mirko",
                            "entity": "user.full_name",
                            "enabled": false,
                            "type": "term",
                            "boolean": "should"
                          },
                          {
                            "filter": "isaia",
                            "entity": "user.full_name",
                            "enabled": false,
                            "type": "term",
                            "boolean": "should"
                          },
                          {
                            "filter": "user:*",
                            "entity": "q",
                            "enabled": false,
                            "type": "term",
                            "boolean": "should"
                          },
                          {
                            "filter": "facebook",
                            "entity": "platform.name",
                            "enabled": false,
                            "type": "term",
                            "boolean": "should"
                          },
              
                          {
                            "filter": "instagram",
                            "entity": "platform.name",
                            "enabled": false,
                            "type": "term",
                            "boolean": "should"
                          },
                          {
                            "filter": "boards.all.suggested.user.full_name:sangalli",
                            "entity": "q",
                            "enabled": false,
                            "type": "term",
                            "boolean": "should"
                          },
                          {
                            "filter": "medium",
                            "entity": "platform.name",
                            "enabled": false,
                            "type": "term",
                            "boolean": "should"
                          },
              
                          {
                            "filter": "boards.all.read.user.full_name:sangalli",
                            "entity": "q",
                            "enabled": false,
                            "type": "term",
                            "boolean": "must_not"
                          },
                          {
                            "filter": "unionesarda.it",
                            "entity": "q",
                            "enabled": false,
                            "type": "term",
                            "boolean": "should"
                          },
                          {
                            "filter": "twitter",
                            "entity": "platform.name",
                            "enabled": false,
                            "type": "term",
                            "boolean": "should"
                          },
                          {
                            "filter": "youtube",
                            "entity": "platform.name",
                            "enabled": false,
                            "type": "term",
                            "boolean": "should"
                          },
                          {
                            "filter": "linkedin",
                            "entity": "platform.name",
                            "enabled": false,
                            "type": "term",
                            "boolean": "should"
                          },
                          {
                            "filter": "blogs",
                            "entity": "platform.name",
                            "enabled": false,
                            "type": "term",
                            "boolean": "should"
                          },
                          {
                            "filter": "spotify",
                            "entity": "platform.name",
                            "enabled": false,
                            "type": "term",
                            "boolean": "should"
                          },
                          {
                            "filter": "shazam",
                            "entity": "platform.name",
                            "enabled": false,
                            "type": "term",
                            "boolean": "should"
                          },
                          {
                            "filter": "dagospia.it",
                            "entity": "site.name",
                            "enabled": false,
                            "type": "term",
                            "boolean": "should"
                          }
                        ],
                        "aggs": null,
                        "with_last_aggs": null,
                        "index_alias": null,
                        "time_mode": "24h",
                        "settings_hide_hidden": true,
                        "settings_hide_open": false
                      },
                      "dashboard": {
                        "filter": {
                          "query": "*"
                        },
                        "from": 0,
                        "size": 40,
                        "sort": {
                          "entity": "rank.beats"
                        },
                        "filters": [
              
                        ],
                        "with_last_aggs": null,
                        "index_alias": null,
                        "filter_time_enabled": false,
                        "time_mode": "dynamic"
                      }
                    }
                  ]
              }
          }
      }


      
    /**
     * Get settings calling api
     * @param {*} options 
     */
    getSettings(options) {
      const url = `${this.settings.url}`;
      //const url = process.env.PUBLIC_URL + '/data/settings.json'
      return axios.get(url);
  }


 }

 export default new Settings();