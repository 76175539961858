import './wall.css';

import React from 'react';
import { FacebookProvider, Like } from 'react-facebook';
import { createBrowserHistory } from 'history';
import { ReactPullToRefresh } from 'react-pull-to-refresh';

import { SocialbeatService } from '../../services/socialbeat';
import Settings from '../../services/settings.js';
import Tracker from '../../services/tracker.js';
import Progress from '../modules/progress.js';
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile
} from "react-device-detect";
import BrickTemplate from '../brick/templates/template.js';
import BrickFabric from '../brick/fabric';
import AliceCarousel from 'react-alice-carousel';
import "react-alice-carousel/lib/alice-carousel.css";
import html2canvas from 'html2canvas';
import Icon from 'react-icons-kit';
import { shareAlt } from 'react-icons-kit/fa/shareAlt';
import { thumbsOUp } from 'react-icons-kit/fa/thumbsOUp';

const Gallery = () => {
  const handleOnDragStart = e => e.preventDefault()
  return (
    <AliceCarousel mouseDragEnabled >
      <img src="https://images.pexels.com/photos/62689/pexels-photo-62689.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260" onDragStart={handleOnDragStart} className="yours-custom-class" />
      <img src="https://images.pexels.com/photos/296878/pexels-photo-296878.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260" onDragStart={handleOnDragStart} className="yours-custom-class" />
      <img src="https://images.pexels.com/photos/1509428/pexels-photo-1509428.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260" onDragStart={handleOnDragStart} className="yours-custom-class" />
    </AliceCarousel>
  )
}

class WallMobile extends React.Component {

  socialbeatService = new SocialbeatService();
  items = [
    { url: "https://images.pexels.com/photos/62689/pexels-photo-62689.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260" },
    { url: "https://images.pexels.com/photos/296878/pexels-photo-296878.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260" },
    { url: "https://images.pexels.com/photos/1509428/pexels-photo-1509428.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260" },
  ]
  openUrl(e, url) {
    e.preventDefault();
    window.open(url, "_blank");

  }


  slideTo = (i) => this.setState({ currentIndex: i })

  onSlideChanged = (e) => this.setState({ currentIndex: e.item })

  slideNext = () => this.setState({ currentIndex: this.state.currentIndex + 1 })

  slidePrev = () => this.setState({ currentIndex: this.state.currentIndex - 1 })

  thumbItem = (item, i) => <span onClick={() => this.slideTo(i)}>* </span>

  onTouch(autoplay = false) {
    if (autoplay) {
      //this.Carousel.slideNext()
      //this.Carousel._playPauseToggle()
    }
    //  this.Carousel._playPauseToggle()
    this.setState({ autoplay })
  }

  galleryItems(stories) {
    const categoryStyle = null;
    const rgba80 = null;
    return stories.map((i) =>
      <div onTouchStart={() => this.onTouch()} onTouchEnd={() => this.onTouch(true)} key={i.story.index} className="brick" style={{ height: this.state.height, width: this.state.width, background: 'url(\'' + i.story.imageUrl + '\') ' + i.story.color.default }}>
        {this.props.wallSettings.stories.template.settings.image.effect === 'tv' &&
        <div>
          <span className="screen"></span>
          <span className="top"></span>
          <span className="bottom"></span>
        </div>
  }
        <div className="header"><div style={{ display: 'table-cell', 'verticalAlign': 'middle' }}><img src={i.story.siteIcon} width='30px' style={{ marginRight: '5px', opacity: 0.7, borderRadius: '100%' }}></img></div><div style={{ display: 'table-cell', 'verticalAlign': 'middle' }}>{i.story.site}</div></div>
        <div className="title-container" onClick={(e) => this.openUrl(e, i.story.url)} style={{ borderColor: i.story.color.default }}>
          {i.story.semantic.ai && <div className="category" style={{ color: i.story.color.default }}>{i.story.places.length > 0 && <span style={{ marginLeft: '-5px', display: 'inline-block', background: rgba80, borderRadius: '3px', padding: '4px', marginRight: '5px', color: 'rgba(255,255,255,0.8)' }}>{i.story.places[0].city.name}</span>}{i.story.semantic.ai.classification.predictions[0].label}</div>}

          <span className="title" style={{ lineHeight: '2.3', boxShadow: '0 0 0 10px ' + i.story.color.default, background: i.story.color.default }}>{i.story.title}</span>
        </div>
      </div>


    )
  }

  constructor(props) {
    super(props);

    this.state = {
      autoplay: true,
      stories: [],
      currentIndex: 0,
      responsive: { 1024: { items: 3 } },
      width: window.innerWidth,
      height: window.innerHeight

    };

    this.start();

  }

  /**
   * Start the wall
   */
  start() {
    // Get data from api
    this.getData();

  }

  /**
   * Get a random color
   * @param {*} options 
   */
  getRandomColor(options) {
    const colors = this.props.wallSettings.colors;
    const type = this.props.wallSettings.stories.selection.color;
    // Sequence mode
    if (type === 'sequence' && options) {
      const offset = options.max / (colors.length);
      let index = parseInt(options.value / offset);
      if (options.value === options.max) { index = index - 1; }
      return colors[index];
    } else {
      // Random mode
      return colors[Math.floor(Math.random() * (colors.length)) + 0]
    }
  }
  /**
   * Get data by api
   */
  getData() {
    // Bricks references for typers
    this.references = {};

    // Get data by socialbeat service
    this.socialbeatService.getChannel({ wall: this.props.wallSettings }).then(
      (stories) => {
        const s = this.socialbeatService.parseStories(stories.data, this, this.props.wallSettings);

        this.setState({ stories: s, galleryItems: this.galleryItems(s) })
        // Parse the stories
        //this.createWall(this.socialbeatService.parseStories(stories.data, this, this.props.wallSettings));
      }
    );
  }

  handleClickTakeScreenShot = () => {
    console.log('handleClickTakeScreenShot')
    const body = document.querySelector('body')

    html2canvas(body).then(canvas => {
      const data = canvas.toDataURL();
      console.log(data)
      this.saveScreenshot(canvas);
    });
  }

  saveScreenshot(canvas) {
    let fileName = "image"
    const link = document.createElement('a');
    link.download = fileName + '.png';
    console.log(canvas)
    canvas.toBlob(function (blob) {
      console.log(blob)
      link.href = URL.createObjectURL(blob);
      link.click();
    });
  };

  getTimeAgo(date) {
    const diff = new Date().getTime() - new Date(date).getTime();
    const t = parseInt((Math.abs(diff) / 1000).toFixed(0), 0);

    let r = t + "s";

    if (t < 60) {
      r = "now";
    }
    else if (t < 3600) {
      r = Number(t / 60).toFixed(0) + "m";
    } else if (t < 7200 * 24) {
      r = Number(t / 3600).toFixed(0) + "h";
    }

    else {
      r = Number(t / 3600 / 24).toFixed(0) + "d";
    }
    return r;
  }
  kFormatter(num) {
    return Math.abs(num) > 999 ? Math.sign(num)*((Math.abs(num)/1000).toFixed(1)) + 'k' : Math.sign(num)*Math.abs(num)
}
    
  render() {
    const handleOnDragStart = e => e.preventDefault()
    const { galleryItems, responsive, currentIndex, stories, autoplay } = this.state
    return (
      <div className="wrapper-container2" >


        <AliceCarousel mouseDragEnabled
          autoPlay={autoplay}

          autoPlayInterval={3000}

          autoHeight={true}
          dotsDisabled={true}
          buttonsDisabled={true}

          items={galleryItems}

          slideToIndex={currentIndex}
          onSlideChanged={this.onSlideChanged}
          ref={(el) => (this.Carousel = el)}
        />

        {stories.length > 0 &&
          <div className="floating-top-right"  onClick={() => this.handleClickTakeScreenShot()}>
            {this.props.wallSettings.stories.template.settings.dateFormat== 'timeAgo' &&
            <span className="time semitransparent" style={{ color: stories[currentIndex].story.color.default }}>{this.getTimeAgo(stories[currentIndex].story.dateCreated)}</span>
            }
            {this.props.wallSettings.stories.template.settings.dateFormat == 'standard' &&
            <span className="time semitransparent standard" style={{ color: stories[currentIndex].story.color.default }}>{new Intl.DateTimeFormat('it-IT', { year: 'numeric', month: 'long', day: '2-digit' }).format(new Date(stories[currentIndex].story.dateCreated))}</span>
          }
            <div className="index" style={{ background: stories[currentIndex].story.color.default }}>
              {currentIndex + 1}°
            </div>

            <div className="index" style={{ background: stories[currentIndex].story.color.default, marginTop:5 }}>

              {this.kFormatter(stories[currentIndex].story.beats)}
              <span style={{  marginLeft:5, borderLeft: 1, borderLeftColor: '#fff'}}>
                    <Icon icon={thumbsOUp} />
                  </span>
            </div>
            {false && autoplay === false &&
              <div>pause</div>}
          </div>
        }
        <div className="overlay-right" >
          <img alt="Socialbeat logo" className="logo cursor-pointer" onClick={(e) => this.openUrl(e, 'https://www.socialbeat.it')} src={process.env.PUBLIC_URL + '/images/logos/socialbeat_logo_white.png'} />
        </div>
        <div className="progress-wrapper">
          {stories.length > 0 &&
            <Progress percent={currentIndex / stories.length} color={stories[currentIndex].story.color.negative} />
          }
        </div>
      </div>
    )
  }
}
export default WallMobile;
