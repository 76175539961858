import axios from 'axios';
import Settings from './settings.js';


export class SocialbeatService {
    entityEndpoint = '';
    constructor() {
        this.settings = Settings;
        this.headers = {
            'Content-Type': 'application/json',
            Authorization: this.settings.user.authHeaders
        }
    }

    getChannel2(options) {
        const url = `${this.settings.broker.url}${this.entityEndpoint}/channels/actuator/`;
        return axios.post(url, options.params, { headers: this.headers });
    }

    searchPlatforms(options) {
        //const url = `https://trends.socialbeat.io/public/data/actuator.json`;
        //const url = `https://matteosalvini.news/public/data/actuator.json`;
        const url = `http://localhost/video/${options.platform}/search`;
        return axios.post(url, options.params);
    }

    getChannel2(options) {
        const url = `https://trends.socialbeat.io/public/data/actuator.json`;
        //const url = `https://matteosalvini.news/public/data/actuator.json`;
        //const url = `${options.wall.data.api.url}`;
        
        return axios.get(url);
    }

    getChannel(options) {
        var headers = {

            'content-type': 'application/json',
            'authorization': 'JWT eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpZCI6ImZmMTIxNWNmYTM1MDExZTc5OWFlMjhjZmU5MWQzZTIxIiwidXNlcl9pZCI6ImZmMTIxNWNmLWEzNTAtMTFlNy05OWFlLTI4Y2ZlOTFkM2UyMSIsInVzZXJuYW1lIjoibWFyY28uc2FuZ2FsbGlAc2Vuc2l0aXZlLmlvIiwiY29tcGFueSI6eyJ1cmwiOiJodHRwOi8vYXBpMy5zb2NpYWxiZWF0LmlvL2FwaS92My9jb21wYW5pZXMvMWRlMjg4ZTEtYTM1Zi0xMWU3LTgzOWYtMjhjZmU5MWQzZTIxLyIsImlkIjoiMWRlMjg4ZTEtYTM1Zi0xMWU3LTgzOWYtMjhjZmU5MWQzZTIxIiwibmFtZSI6InNvY2lhbGJlYXQuaW8iLCJkYXRlX2NyZWF0ZWQiOiIyMDIwLTAzLTIwVDE0OjA0OjIyLjQyMzUzMCswMTowMCIsInBsYWNlcyI6W119LCJmaXJzdF9uYW1lIjoiTWFyY28iLCJsYXN0X25hbWUiOiJTYW5nYWxsaSIsImVtYWlsIjoibWFyY28uc2FuZ2FsbGlAc2Vuc2l0aXZlLmlvIiwicGhvbmUiOm51bGwsInJvbGUiOiJnZW5lcmljLnJlYWRlciIsInNwb2tlX2dyb3VwcyI6W10sImNvbXBhbmllcyI6W3siaWQiOiJkZTc3YWUwMmEzNTExMWU3ODc5YzI4Y2ZlOTFkM2UyMSJ9LHsiaWQiOiJlYzc0ZmViMmU1MTcxMWU5YTc2NjBhNjAyNjc5Zjc3MCJ9XSwic2V0dGluZ3Nfc2NoZWR1bGVyX2VuYWJsZWQiOnRydWUsInNldHRpbmdzX2JvYXJkX2VuYWJsZWQiOnRydWUsInNldHRpbmdzX25vdGlmaWNhdG9yX2VuYWJsZWQiOnRydWUsInJvbGVzIjp7ImlzX3N1cGVydXNlciI6dHJ1ZSwiaXNfYWN0aXZlIjp0cnVlLCJpc19zdGFmZiI6dHJ1ZX0sImdlbmRlciI6Im0iLCJwbGFjZXMiOltdLCJwbGFjZSI6eyJjb3VudHJ5Ijp7ImlkIjoiaXQifX0sImxhbmd1YWdlIjp7ImlkIjoiaXQifX0.WRkI7_1l1_U75Ss7nnWbxL_C1S5ctx0NGCb2jlrhqGk',

        };
        var dataString = {"id":"b1758b13-e56c-4de2-b20f-1e9b916ecceb"};

        var options = {
            url: 'https://api.spikes.news/channel/publications',
            method: 'POST',
            headers: headers,
            body: dataString
        };
        let config = {
            headers: headers
          }
          
        return axios.post(options.url, JSON.stringify(dataString), config);
    }

    getChannel2(options) {
        var headers = {

            'content-type': 'application/json',
            'authorization': 'JWT eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpZCI6ImZmMTIxNWNmYTM1MDExZTc5OWFlMjhjZmU5MWQzZTIxIiwidXNlcl9pZCI6ImZmMTIxNWNmLWEzNTAtMTFlNy05OWFlLTI4Y2ZlOTFkM2UyMSIsInVzZXJuYW1lIjoibWFyY28uc2FuZ2FsbGlAc2Vuc2l0aXZlLmlvIiwiY29tcGFueSI6eyJ1cmwiOiJodHRwOi8vYXBpMy5zb2NpYWxiZWF0LmlvL2FwaS92My9jb21wYW5pZXMvMWRlMjg4ZTEtYTM1Zi0xMWU3LTgzOWYtMjhjZmU5MWQzZTIxLyIsImlkIjoiMWRlMjg4ZTEtYTM1Zi0xMWU3LTgzOWYtMjhjZmU5MWQzZTIxIiwibmFtZSI6InNvY2lhbGJlYXQuaW8iLCJkYXRlX2NyZWF0ZWQiOiIyMDIwLTAzLTIwVDE0OjA0OjIyLjQyMzUzMCswMTowMCIsInBsYWNlcyI6W119LCJmaXJzdF9uYW1lIjoiTWFyY28iLCJsYXN0X25hbWUiOiJTYW5nYWxsaSIsImVtYWlsIjoibWFyY28uc2FuZ2FsbGlAc2Vuc2l0aXZlLmlvIiwicGhvbmUiOm51bGwsInJvbGUiOiJnZW5lcmljLnJlYWRlciIsInNwb2tlX2dyb3VwcyI6W10sImNvbXBhbmllcyI6W3siaWQiOiJkZTc3YWUwMmEzNTExMWU3ODc5YzI4Y2ZlOTFkM2UyMSJ9LHsiaWQiOiJlYzc0ZmViMmU1MTcxMWU5YTc2NjBhNjAyNjc5Zjc3MCJ9XSwic2V0dGluZ3Nfc2NoZWR1bGVyX2VuYWJsZWQiOnRydWUsInNldHRpbmdzX2JvYXJkX2VuYWJsZWQiOnRydWUsInNldHRpbmdzX25vdGlmaWNhdG9yX2VuYWJsZWQiOnRydWUsInJvbGVzIjp7ImlzX3N1cGVydXNlciI6dHJ1ZSwiaXNfYWN0aXZlIjp0cnVlLCJpc19zdGFmZiI6dHJ1ZX0sImdlbmRlciI6Im0iLCJwbGFjZXMiOltdLCJwbGFjZSI6eyJjb3VudHJ5Ijp7ImlkIjoiaXQifX0sImxhbmd1YWdlIjp7ImlkIjoiaXQifX0.WRkI7_1l1_U75Ss7nnWbxL_C1S5ctx0NGCb2jlrhqGk',

        };
        
        var dataString = [
          {
            "params": {
              "filter": {
                "query": "*"
              },
              "from": 0,
              "size": 16,
              "sort": {
                "entity": "rank.beats",
                "rank_max": 100000,
                "gravity": 3.5,
                "direction": "desc"
              },
              "filters": [
                {
                  "filter": "it",
                  "entity": "country.id",
                  "enabled": true,
                  "type": "term",
                  "boolean": "should"
                },
                {
                  "filter": "news",
                  "entity": "platform.name",
                  "enabled": true,
                  "type": "term",
                  "boolean": "should"
                },
                {
                  "filter": {
                    "gte": "now-662m",
                    "lte": "now-0m"
                  },
                  "entity": "created",
                  "enabled": true,
                  "type": "range",
                  "boolean": "must"
                }
              ],
              "aggs": null,
              "with_last_aggs": null,
              "index_alias": null,
              "time_mode": "today",
              "settings_hide_hidden": true,
              "settings_hide_open": false,
              "settings_beats_gte": 0
            },
            "dashboard": {
              "filter": {
                "query": "*"
              },
              "from": 0,
              "size": 10,
              "sort": {
                "entity": "rank.beats",
                "rank_max": 100000,
                "gravity": 3.5
              },
              "filters": [],
              "with_last_aggs": null,
              "index_alias": null,
              "filter_time_enabled": false,
              "time_mode": "dynamic"
            }
          }
        ];
        
        var options = {
            url: 'https://api3.socialbeat.io/broker/api/v3/channels/actuator/',
            url2: 'http://localhost:8003/broker/api/v3/channels/actuator/',
            method: 'POST',
            headers: headers,
            body: JSON.stringify(dataString)
        };
        let config = {
            headers: headers
          }
          
        return axios.post(options.url, JSON.stringify(dataString), config);
    }

    getMax(stories) {
        console.log(stories);
        return stories[0]._source.updates.quantums.graph.interactions.all['1y'][0].increment.all;
    }

    textParse(text) {

        //text = text.split(' - ')[0];
        text = text.replace(' -  - Varese News', '');
        text = text.replace(' - - Varese News', '');
        text = text.replace(' - Varese News', '');
        text = text.replace(' - Varesenews', '');
        if (text.length > 120) text = text.substr(0, 120) + '...'
        return text

    }
    preloadImage(item) {
        const img = new Image();
        img.src = item.imageUrl;
        console.log('preloadImage', item.imageUrl);

    }



    parseStories(response, wall, wallSettings) {
        console.log(response);
        const storyStart = wallSettings.stories.selection.storyStart || 0;
        const storyEnd = wallSettings.stories.selection.storyEnd || response.publication.length;

        const objects = response.publication.splice(storyStart, storyEnd);
        console.log('wall objects', objects);
        const max = objects[0].platforms_reactions ? objects[0].platforms_reactions.all['1y'][0].increment.all:0;
        const stories = [];

        objects.forEach((item, index) => {
            let title = this.textParse(item.title);
            console.log('wall item', item);
            const s = {
                id: index,
                index: index,
                delay: index * 100 * 3,
                storyId: item.id||'xxxx',
                title: title,
                dateCreated: item.created,
                //semantic: item._source.metadata.application.semantic,
                classification: item.classification,
                places: item.place || [],
                url: item.url,
                imageUrl: item.image_url,
                site: item.site_name,
                siteIcon: 'https://encrypted-tbn0.gstatic.com/faviconV2?url=http://' + item.site_name + '&client=NEWS_360&size=96&type=FAVICON&fallback_opts=TYPE,SIZE,URL',
                color: wall.getRandomColor({ type: 'impact', value: item.platforms_reactions_total,  max: max }),
                colorOriginal: wall.getRandomColor({ type: 'impact', value: item.platforms_reactions_total,  max: max }),

                beats: item.platforms_reactions ? item.platforms_reactions.all['1y'][0].increment.all:0,
                comment: item.platforms_reactions ?item.platforms_reactions.all['1y'][0].increment.comment:0,
                share: item.platforms_reactions ?item.platforms_reactions.all['1y'][0].increment.share:0,
                reaction: item.platforms_reactions ?item.platforms_reactions.all['1y'][0].increment.reaction:0,
                //sorterData: item.sort[0],
                style: 'true',
                firstTime: 'true'
            }
            if (index < 10) {
                this.preloadImage(s);
            }
            stories.push({ story: s });
            console.log('wall stories', stories);
        });
        //this.preloadImages(stories);
        return stories;
    }

    preloadImages(stories){
        let k = 9;
        const timer = setInterval(() => {
            this.preloadImage(stories[k].story);
            k++;
            if (k==stories.length-1) {clearInterval(timer);}
        }, 1000);
    }

}
