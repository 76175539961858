
export default class User {
    authHeaders = 'JWT eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VybmFtZSI6Im1hcmNvLnNhbmdhbGxpQHNlbnNpdGl2ZS5pbyIsInVzZXJfaWQiOiJmZjEyMTVjZi1hMzUwLTExZTctOTlhZS0yOGNmZTkxZDNlMjEiLCJlbWFpbCI6Im1hcmNvLnNhbmdhbGxpQHNlbnNpdGl2ZS5pbyIsImV4cCI6MTYyMDIxODk3NH0.TF6DqQOPconwc1rBNzMuP7T5Vd_V_3BS3OfYnaYOH3k';
    hash = null;
    constructor(){
      this.hash = this.generateCanvasFingerprint();
      console.log('hash', this.hash);
    }
  
    generateCanvasFingerprint() {
      // https://browserleaks.com/canvas
      // https://multilogin.com/everything-you-need-to-know-about-canvas-fingerprinting/
      // https://github.com/Valve/fingerprintjs2
      // Text with lowercase/uppercase/punctuation symbols

      var canvas = document.createElement('canvas');
      var ctx = canvas.getContext('2d');
      var txt = "socialbeat <canvas> 1.0";
      ctx.textBaseline = "top";
      // The most common type
      ctx.font = "14px 'Arial'";
      ctx.textBaseline = "alphabetic";
      ctx.fillStyle = "#f60";
      ctx.fillRect(125, 1, 62, 20);
      // Some tricks for color mixing to increase the difference in rendering
      ctx.fillStyle = "#069";
      ctx.fillText(txt, 2, 15);
      ctx.fillStyle = "rgba(102, 204, 0, 0.7)";
      ctx.fillText(txt, 4, 17);
      var createHash = require('create-hash')
      var hash = createHash('md5')
      hash.update(canvas.toDataURL()) // optional encoding parameter
      return hash.digest("hex")

  
    }
  }
