import './wall.css';

import React from 'react';
import { FacebookProvider, Like } from 'react-facebook';
import { createBrowserHistory } from 'history';
import { ReactPullToRefresh } from 'react-pull-to-refresh';

import { SocialbeatService } from '../../services/socialbeat';
import Settings from '../../services/settings.js';
import Tracker from '../../services/tracker.js';
import Progress from '../modules/progress.js';
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile
} from "react-device-detect";
import BrickTemplate from '../brick/templates/template.js';
import BrickFabric from '../brick/fabric';
import { exception } from 'react-ga';
import Stats from '../analytics/stats';

class Wall extends React.Component {
  x = 0;
  socialbeatService = new SocialbeatService();
  brickMouseOver;
  onWheelInterval;

  constructor(props) {
    super(props);
    this.state = {
      tension: 0,
      brickUpdaterStatus: null,
      history: createBrowserHistory(),
      repeat: 0,                // Repeat stories
      updateQueueBricks: [],
      rows: [],
      cols: [],
      list: [],
      stories: [],
      updatesTimes: 0,
      timerL: 0,
      lastStoryIndex: this.props.wallSettings.stories.matrix.tot - 1 || 0, // Last story pushed in the matrix, at starting is the last of matrix

    };
    console.log('this.props.wallSettings.stories.matrix.tot', this.props.wallSettings.stories.matrix.tot);
    console.log('params this.props.wallSettings.stories.matrix.tot', this.props.publications);
    this.start();

  }

  /**
   * Start the wall
   */
  start() {
    // Get data from api
    this.getData();
    // Start the bricks updater
    this.startBrickUpdater();
    // Start data updater
    // TODO: moving update at the end of bricks shown?
    this.startDataUpdater();
  }

  /**
   * Get data by api
   */
  getData() {
    // Bricks references for typers
    this.references = {};
    let stories;
    if(this.props.publications){
      this.timerStartAnimation = setTimeout(() => {
      console.log('wall go');
      /*
      let stories = [];
      this.props.wallSettings.publications.publication.forEach((item, index) => {
        stories.push({story:item});
      });*/
      console.log('this.props.publications', this.props.publications);
      stories = this.socialbeatService.parseStories(this.props.publications, this, this.props.wallSettings);
      console.log('wall stories', stories);
      return this.createWall(stories);
    }, 500);
    
    }else{

    // Get data by socialbeat service
    this.socialbeatService.getChannel({ wall: this.props.wallSettings }).then(
      (stories) => {
        stories = this.socialbeatService.parseStories(stories.data, this, this.props.wallSettings);
        // Parse the stories
        this.createWall(stories);
        console.log('wall stories', stories);
      }
    );
  }
  }

  /**
   * Create Wall
   * @param {*} response 
   */
  createWall(stories) {
    console.log('wall stories2', stories);
    const updateList = [];
    const maxBricks = this.props.wallSettings.stories.matrix.tot;

    // Set stories to the state
    this.setState({ stories: stories });

    // Create the base matrix based on rows*cols
    stories.forEach((story, index) => {
      console.log('stories2 story', story);
      // First Time
      if (this.state.updatesTimes === 0 && index < maxBricks) {
        console.log('stories2 add', story);
        // Push story to the list
        updateList.push({ story: story.story });
        // Create the zeros references
        this.references[index] = { story: null, site: null, beats: null };
      }
    });

    console.log('stories2 updateList', updateList);
    // Show bricks with animation
    // adding story in state.list
    // With a delay
    if (this.state.updatesTimes === 0) {
      this.timerStartAnimation = setInterval(() => {
        const list = this.state.list;
        list.push(updateList[this.state.timerL]);
        this.setState({ timerL: this.state.timerL + 1, list: list });
        if (updateList.length === this.state.timerL) {
          clearInterval(this.timerStartAnimation);
        }
      }, 50);
    }

  }

  /**
* Get data by api
*/
  searchPlatform(brick, story, platform) {
    const names = [{ s: 'alberto angela' }, { s: 'boldrini' }, { s: 'roberto gualtieri' }, { s: 'berlusconi' }, { s: 'bekele' }, { s: 'salvini', q: 'matteo salvini' }, { s: 'di maio' }, { s: 'giuseppe conte' }, { s: 'hamilton', q: 'lewis hamilton' }, { s: 'maria de filippi' }, { s: 'papa', q: 'papa francesco' }, { s: 'balotelli', q: 'mario balotelli' }];
    console.log('story.title', story.title);
    console.log('story.title', story.title.toLowerCase().search(/piero angela|matteo salvini/));
    let found;
    let updated = false;
    names.forEach((el) => {
      if (!updated && story.title.toLowerCase().indexOf(el.s) >= 0) {
        updated = true;
        found = el.q || el.s;
      }
    });




    console.log('found', found);
    if (!found) {
      const platforms = brick.state.platforms;
      console.log('platforms', platforms);

      platforms[platform] = { videos: [] };
      brick.setState({ platforms: platforms });

      return
    };
    const options = { platform: platform, params: { q: found } }
    // Get data by socialbeat service
    this.socialbeatService.searchPlatforms(options).then(
      (stories) => {
        const platforms = brick.state.platforms;
        platforms[options.platform] = { videos: stories.data.video };
        console.log('platforms', platforms);
        console.log('platforms stories', stories);
        brick.setState({ platforms: platforms });
      }
    );
  }
  sleep(milliseconds) {
    var start = new Date().getTime();
    for (var i = 0; i < 1e7; i++) {
      if ((new Date().getTime() - start) > milliseconds) {
        break;
      }
    }
  }
  getAvaiableBricks(options) {
    let avaiable = this.state.list.filter(item => item.typer.state.isDone === true);
    if (options && options.altKey) avaiable = this.state.list.filter(item => item.typer.state.isDone === true && item.brick.props.index === this.brickMouseOver.props.index);
    if (options && options.direction === -1) return avaiable.sort((a, b) => b.story.index - a.story.index);
    return avaiable.sort((a, b) => a.story.index - b.story.index);
  }
  /**
   * Update brick called by timerBrickUpdater
   */
  onUpdateBrick(options = { direction: 1, force: false }) {
    // Get a new brick
    let arr = [];
    let list = this.state.list;
    let lastStoryIndex = this.state.lastStoryIndex;
    console.log('onUpdateBrick lastStoryIndex', this.state.lastStoryIndex);
    console.log('options', options)
    // Force a brick to update: eg, onWheel
    if (list && options.force) {

      // Get bricks avaiable, state.isDone
      // And sort by story index, update lower first
      let avaiable = this.getAvaiableBricks();
      // Not avaiable, return
      if (avaiable.length === 0) return;
      // If direction is negative, back
      // Sort
      if (options.direction === -1) {
        // Get the lower story index on brick
        lastStoryIndex = avaiable[0].story.index;
        // Sort
        avaiable = avaiable.sort((a, b) => b.story.index - a.story.index);
      } else {
        // Get the higher story index
        lastStoryIndex = avaiable[avaiable.length - 1].story.index;
      }



      //let rand = Math.floor((Math.random() * avaiable.length) + 0);
      let rand = 0;
      // With altKey update the brick where the mouse is
      if (options.altKey) {
        avaiable = this.state.list.filter(item => item.typer.state.isDone === true && item.brick.props.index === this.brickMouseOver.props.index);
        rand = 0;
      }
      console.log('altKey', options.altKey);
      console.log('altKey', rand);
      // Compose
      arr = avaiable[rand] ? [avaiable[rand]] : [];


    } else {
      console.log('this.state.updateQueueBricks', this.state.updateQueueBricks);
      // Get new fro avaiable bricks
      arr = this.state.updateQueueBricks.splice(0, 1);
    }


    // If not bricks in the queue return
    if (arr.length === 0) { return };

    // Get the brick
    const ref = arr[0];
    // Get a new story
    options.lastStoryIndex = lastStoryIndex;
    let story = this.getRandomStory(options);

    if (story && this.state.list.filter(item => item.story.storyId === story.story.storyId).length > 0) {
      console.log('EXISTS', options.lastStoryIndex)
      lastStoryIndex = lastStoryIndex + 1;
      options.lastStoryIndex++;
      console.log('EXISTS TRY NEXT', options.lastStoryIndex)
      story = this.getRandomStory(options);
    }

    if (!story) return;
    //if (!story || this.state.list.filter(item => item.story.storyId === story.story.storyId).length > 0) return;

    // Not a first time story
    story.firstTime = 'false';

    list[ref.brick.props.index].story = story.story;

    this.setState({ list: list });
    ref.brick.restart({ story, force: options.force })

    //this.searchPlatform(ref.brick, story, 'rai');
    //this.searchPlatform(ref.brick, story, 'youtube');

  }

  /**
   * Add brick to the updateQueue
   */
  onAddBrick = (item) => {
    console.log('onAddBrick', item);
    item.brick.lastUpdatedDate = Date.now();
    // Set the style to false
    item.brick.setState({ style: 'false' });
    // Add to update queue
    this.state.updateQueueBricks.push(item);
  };

  /**
   * Get a random color
   * @param {*} options 
   */
  getRandomColor(options) {
    const colors = this.props.wallSettings.colors;
    const type = this.props.wallSettings.stories.selection.color;
    // Sequence mode
    if (type === 'sequence' && options) {
      const offset = options.max / (colors.length);
      let index = parseInt(options.value / offset);
      if (options.value === options.max) { index = index - 1; }
      return colors[index];
    } else {
      // Random mode
      return colors[Math.floor(Math.random() * (colors.length)) + 0]
    }
  }

  /**
   * Return true if wall need to be repeated (settings: stories.repeat=n)
   */
  repeat() {
    const repeat = this.props.wallSettings.stories.repeat || Settings.defaultWallStoriesRepeat;
    // Repeat
    this.setState({ 'repeat': this.state.repeat + 1 });
    return repeat === this.state.repeat - 1;
  }

  /**
   * Get a random story
   */
  getRandomStory(options) {
    let story;
    //let lastStoryIndex = this.state.lastStoryIndex;
    let lastStoryIndex = options.lastStoryIndex;
    console.log('getRandomStory lastStoryIndex', lastStoryIndex);
    // Sequence
    if (this.props.wallSettings.stories.selection.type !== 'random') {
      console.log('lastStoryIndex', lastStoryIndex);
      console.log('this.state.stories.length - 1', this.state.stories.length);
      // If is it the last story?
      if (this.state.lastStoryIndex === this.state.stories.length -1) {
        // Set the pointer to first story
        console.log('SET lastStoryIndex to 0')
        lastStoryIndex = 0;
        this.setState({ lastStoryIndex: lastStoryIndex });
        // Select a new wall if exists and if not need another repetition
        if (this.repeat()) {
          this.props.dashboard.selectWall({ repeat: this.state.repeat });
          Tracker.event({
            category: 'UI',
            action: 'getRandomStory.repeat',
            label: this.state.history.location.pathname,
            value: this.state.repeat
          });
          console.log('getRandomStory: repeat', this.state);
        };

      } else {
        const deltaDirection = options.direction === 1 ? 1 : -1;
        lastStoryIndex = lastStoryIndex + deltaDirection;
        // Next story
        this.setState({ 'lastStoryIndex': lastStoryIndex })
      }
      Tracker.event({
        category: 'UI',
        action: 'getRandomStory',
        label: this.state.history.location.pathname,
        value: lastStoryIndex
      });

      // Selected story
      story = this.state.stories[lastStoryIndex];
      // Preload the next image
      if(this.state.lastStoryIndex < this.state.stories.length -1){
        this.socialbeatService.preloadImage(this.state.stories[lastStoryIndex+1].story);
      }
    } else {
      // Random
      story = this.state.stories[Math.floor(Math.random() * (this.state.stories.length - 1)) + 0];
      story.color = this.getRandomColor({ type: 'impact', max: this.state.stories[0].beats, value: story.beats });

    }
    // Not first time
    if (story) story.firstTime = false;
    return story;
  }



  /**
   * Open a new url
   * @param {*} e 
   * @param {*} url 
   */
  openUrl(e, url) {
    e.preventDefault();
    window.open(url, "_blank");

  }

  /**
   * Start the data updater
   */
  startDataUpdater() {
    this.timerDataUpdater = setInterval(() => {

      this.setState({ updatesTimes: this.state.updatesTimes + 1 });
      //this.getData();
    }, Settings.api.socialbeat.actuator.autoUpdate.time);
  }

  /**
   * Start the brick updater
   */
  startBrickUpdater() {

    this.setState({ brickUpdaterStatus: 'running' });
    this.timerBrickUpdater = setInterval(() => {
      // If pause time is reached
      // and number of parallel updates allowed is reached
      if (
        this.state.list.length > 0 && (Date.now() - this.state.list[0].brick.lastUpdatedDate) >= this.props.wallSettings.stories.updater.waiting &&
        this.state.list.filter(item => item.typer.state.isDone === false).length <= this.props.wallSettings.stories.updater.maxParallel - 1
      ) {

        this.onUpdateBrick();

      }

    }, 500);
  }

  /**
   * Stop the bricks updater
   */
  stopBrickUpdater(status = 'paused') {
    this.setState({ brickUpdaterStatus: status });
    clearInterval(this.timerBrickUpdater);
  }

  /**
   * Stop the bricks updater
   */
  stopDataUpdater() {
    clearInterval(this.timerDataUpdater);
  }

  stopUpdaters() {
    this.stopBrickUpdater();
    this.stopDataUpdater();
  }
  componentWillUnmount() {
    this.stopUpdaters();
  }

  getBrick(value, index) {
    const TagName = BrickFabric.get(this.props.wallSettings.stories.template.type);
    console.log('stories2 this.state.list[index]', this.state.list[index])
    return <TagName key={index} story={this.state.list[index]} index={index} wall={this} wallBrick={this.state.list[index]}></TagName>
  }
  getTimeAgo(date) {
    const diff = new Date().getTime() - new Date(date).getTime();
    const t = parseInt((Math.abs(diff) / 1000).toFixed(0), 0);

    let r = t + "s";

    if (t < 60) {
      r = "now";
    }
    else if (t < 3600) {
      r = Number(t / 60).toFixed(0) + "m";
    } else if (t < 7200 * 24) {
      r = Number(t / 3600).toFixed(0) + "h";
    }

    else {
      r = Number(t / 3600 / 24).toFixed(0) + "d";
    }
    return r;
  }
  change(e, story, index) {
    //console.log(this.state.list);
    //console.log(story);
    //this.setState({ list: [story] });
    //console.log('list', this.state.list);
    e.preventDefault();
    window.open(story.url, "_blank");


  }

  onPause(e, status) {
    console.log('e.type', e.type);

    console.log(e);
    if (status === 'stopped') {
      console.log(status)
      let x = 0;
      this.buttonPressTimer = setInterval(() => {
        if (x === 1) {
          console.log('x', x);
          this.setState({ fromStop: true });
          this.stopBrickUpdater(status);
          clearInterval(this.buttonPressTimer);
        };

        x++;
      }, 500);

    }

    /*
    if (status === 'stopped') {
      console.log('stopped')

      this.buttonPressTimer = setTimeout(() => {
        this.stopBrickUpdater(status);
      }, 500);

    }*/
    if (e.type === 'mouseup') {
      //this.setState({fromStop: false});
      setTimeout(() => {
        this.setState({ fromStop: false });
      }, 500);
      clearInterval(this.buttonPressTimer);
    }

    if (e.type === 'click') {
      //this.state.updateQueueBricks=[];
      this.state.list.map(item => {
        this.onAddBrick(item);
      });
      console.log('list', this.state.list);
      //clearTimeout(this.buttonPressTimer);
      clearInterval(this.buttonPressTimer);
      this.onUpdateBrick();
      this.startBrickUpdater();


    }


  }

  onWheel(event, wait = 2) {
    return;
    console.log('wait', this.onWheelInterval);
    // Stop brick updater
    this.stopBrickUpdater('stopped');
    // Get wheel direction up/downs
    const direction = event.deltaY > 0 ? 1 : -1;
    // Get altKey
    const altKey = event.altKey;
    // Is first or last, return
    if ((this.state.lastStoryIndex === 0 && direction === -1) || (this.state.lastStoryIndex === this.state.stories.length - 1 && direction === 1)) return;
    // Not wheelinterval active, create a new onw
    if (!this.onWheelInterval) {
      const avaiable = this.getAvaiableBricks({ direction: direction, force: true, altKey: altKey });
      if (avaiable.length === 0) return;
      this.setState({ selectedAvaiable: avaiable[0].brick.props.index, selectedAvaiableDirection: direction })
      // Wait counter

      this.setState({ tension: 0 });
      this.onWheelInterval = setTimeout(() => {
        console.log('x', this.state.tension);
        // If wait end
        if (true || this.state.tension >= wait) {

          // Update brick
          this.onUpdateBrick({ direction: direction, force: true, altKey: altKey });
          // Remove interval

        };
        clearInterval(this.onWheelInterval);
        this.onWheelInterval = null;
        this.setState({ selectedAvaiable: null, selectedAvaiableDirection: null, tension: 0 })


      }, 300);

    }

    this.setState({ tension: this.state.tension + 1 });
    /*
    if (event.deltaY < 5) return;
    // Get the direction
    const direction = event.deltaY > 0 ? 1 : -1;
    // If is the first story and drie
    console.log('direction', direction);
    console.log(this.state.lastStoryIndex, this.props.wallSettings.stories.matrix.tot)
    if (this.state.lastStoryIndex === 0 && direction === -1) return;
    this.onUpdateBrick({ direction: direction, force: true, altKey: event.altKey });
    this.stopBrickUpdater('stopped');
    */
    /*
      let scrollTop = event.srcElement.body.scrollTop,
          itemTranslate = Math.min(0, scrollTop/3 - 60);
  
      this.setState({
        transform: itemTranslate
      });
      */
  }

  getChromeTab() {
    console.log('xxx')
    try {
      window.chrome.tabs.query({ 'active': true, 'lastFocusedWindow': true }, function (tabs) {
        var url = tabs[0].url;
        console.log('getChromeTab', url);
      });
    } catch (err) {

    }


  }

  render() {

    const latestStory = this.state.stories[this.state.lastStoryIndex];
    const startIndex = Math.max(this.state.lastStoryIndex - 8, 0);
    const stopIndex = Math.min(this.state.lastStoryIndex + 8, this.state.stories.length);
    return (

      <div className="wall" onWheel={(e) => this.onWheel(e)} onMouseDown={(e) => this.onPause(e, 'stopped')} onMouseUp={(e) => this.onPause(e, 'running')}>
        {(this.state.brickUpdaterStatus === 'paused' || this.state.brickUpdaterStatus === 'stopped') && <div className="brick-updater-status cursor-pointer "><span onClick={(e) => this.onPause(e, 'running')} className="brick-updater-button">{this.state.brickUpdaterStatus}</span>
        </div>}
        <div className="overlay-right">
        {false &&
          <Stats wall={this}></Stats>
        }
          {false &&
            <FacebookProvider appId="709701945846774">
              <Like href="https://trends.socialbeat.io" colorScheme="dark" showFaces share />
            </FacebookProvider>
          }
          <img alt="Socialbeat logo" className="logo cursor-pointer" onClick={(e) => this.openUrl(e, 'https://www.socialbeat.it')} src={process.env.PUBLIC_URL + '/images/logos/socialbeat_logo_white.png'} />
        </div>
        <BrowserView>
          <div className="overlay-left-top">
            {this.props.wallSettings && this.props.wallSettings.menu.storiesPreview.enabled &&
              <div className="stories-preview ">

                {this.state.stories.slice(startIndex, stopIndex).map((story, index) => {
                  return (
                    <div className={'cursor-pointer story-preview current-' + (this.state.lastStoryIndex === index + startIndex)} onClick={(e) => this.change(e, story, index)} >
                      <div className="col col-image">
                        <img src={story.imageUrl} style={{ borderColor: story.color.default }}></img>
                      </div>
                      <div className="col story-title-preview">
                        <div className="site">{story.site} - {this.getTimeAgo(story.dateCreated)}</div>
                        <span className="title" style={{ background: story.color.default, boxShadow: '0 0 0 3px ' + story.color.default }}>{story.title}</span>
                      </div>
                    </div>
                  )

                })}
              </div>
            }
          </div>
        </BrowserView>

        
        {this.state.list.map((value, index) => {
          return (
            this.getBrick(value, index)
          )

        })}
        <div className="progress-wrapper">
          {latestStory &&
            <Progress percent={(this.state.lastStoryIndex +1) / (this.state.stories.length)} color={latestStory.story.color.negative} />
          }
        </div>





      </div>


    )
  }
}
export default Wall;
