import React from 'react';
import './stats.css';
import { SocialbeatService } from '../../services/socialbeat';
import Progress from '../modules/progress';
//import { Button, Progress } from 'semantic-ui-react'

class Stats extends React.Component {



  constructor(props) {
    super(props);


  }


  kFormatter(num) {
    if(Math.abs(num) > 999999){
      return Math.sign(num)*((Math.abs(num)/1000000).toFixed(1)) + 'm'
    }else{
      return Math.abs(num) > 999 ? Math.sign(num)*((Math.abs(num)/1000).toFixed(1)) + 'k' : Math.sign(num)*Math.abs(num)
    }
    
}

  getSites() {
    if (this.props.wall.state.stories.length > 0) {
      console.log('result', this.props.wall.state.stories);
      const totBeats = 0;
      const map = this.props.wall.state.stories.reduce((result, filter) => {
        //console.log('result', filter);
        //result[filter.story.story.site] = filter.story.story.site;
        if (!result.sites[filter.story.site]) result.sites[filter.story.site] = { beats: 0, count: 0 }
        result.sites[filter.story.site].beats = result.sites[filter.story.site].beats + filter.story.beats;
        result.sites[filter.story.site].count = result.sites[filter.story.site].count + 1;
        result.metadata.beatsTotal = result.metadata.beatsTotal + filter.story.beats;
        return result;
      }, { sites: {}, metadata: { beatsTotal: 0 } });
      //console.log('map', map);

      let sitesArray = Object.keys(map.sites).map(key => { return { id: key, data: map.sites[key] } });
      sitesArray = sitesArray.sort((item0, item1) => item1.data.beats - item0.data.beats).slice(0, 10);
      //console.log('sites', sitesArray);
      map.sites = sitesArray;
      return map;
    }
  }
  render() {
    const map = this.getSites();
    return <div>
      {this.props.wall.state.stories.length > 0 && map.sites.length>1 && 
      <div className="stats-background">

        {map.sites.map((story, index) => {
          return (
            <div className="item">
              <div className="label">
                {story.id} - {this.kFormatter(story.data.beats)}
              </div>
              <Progress size="tiny" percent={story.data.beats / map.sites[0].data.beats} color="green">

              </Progress>
            </div>
          )

        })}
      </div>
    }
    </div>
  }
}
export default Stats;

