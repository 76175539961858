import './dashboard.css';
import React from 'react';
import { createBrowserHistory } from 'history';

import Settings from '../../services/settings.js';
import Tracker from '../../services/tracker.js';
import Wall from '../wall/wall.js'
import WallMobile from '../wall2/wall.js'
import FilterSelector from '../filter-selector/filter-selector.js';
import MatrixSelector from '../matrix-selector/matrix-selector.js';
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
  deviceType
} from "react-device-detect";
import 'url-search-params-polyfill';

class Dashboard extends React.Component {

  constructor(props) {
    super(props);
    console.log('Dashboard init');
    const params = new URLSearchParams(createBrowserHistory().location.search);
    console.log('params', createBrowserHistory().location.search);
    console.log('params', JSON.parse(params.get('publications')));
    // Component state
    this.state = {
      settings: { data: null },
      walls: [],              // Wall settings
      selectedWallIndex: 0,   // Selected wall    
      history: createBrowserHistory(),
      publications: JSON.parse(params.get('publications'))
    };
    console.log('params', this.state)
    this.state.startChannelId = this.createChannelId();

    

    this.state.deviceType = this.state.history.location.search.indexOf('isMobile=false') >= 0 ? 'browser' : deviceType;
    this.state.deviceType = this.state.history.location.search.indexOf('isMobile=true') >= 0 ? 'mobile' : this.state.deviceType;

    console.log('startChannelId', this.state.startChannelId);
    // Get the settings calling the api
    Settings.getSettings().then(settings => {
      console.log('settings', settings);
      // Set walls config with channels
      this.updateSettings(settings);
      // Select a starting wall
      this.selectWall({ selectedWallIndex: 0, wall: this.state.walls[0] });
      if(params.get('publications')){
        return;
      }

      // There are paths: eg. /2019/giornaledibrescia.it
      if (this.state.history.location.pathname !== "/") {
        // Get the channel
        const w = this.searchWall(settings, this.getChannelIdByUrl())[0];
        // Update the wall
        this.changeWall(w, 0);
      } else {
        // Is the root: eg. https://trends.socialbeat.io/
        // And we have a selected startChannelId: eg. 2019_giornaledibrescia.it
        // Use it
        if (this.state.startChannelId !== '') {
          // Get the channel saved
          const w = this.searchWall(settings, this.state.startChannelId)[0];
          // Update the wall
          this.changeWall(w, 0);
        }
      }
    })
  }


  /**
   * Get channel id by url
   */
  getChannelIdByUrl() {
    //return window.location.hostname + this.state.history.location.pathname.split('/').join('_');
    return this.state.history.location.pathname.split('/').slice(1).join('_')||'country_italy';
  }

  /**
   * Create a start id by url: trends.socialbeat.io/province/bergamo -> trends.socialbeat.io_province_bergamo
   * and save it as starting settings
   */
  createChannelId(create = true) {
    return 'country_italy';
    let id = localStorage.getItem('sb_trends_startWallId');
    console.log('sb_startWallId', '['+id+']');
    if (!id) {
      id = this.getChannelIdByUrl();
      if(this.state.history.location.search.indexOf('ignoreFirstChannel')<0){
        localStorage.setItem('sb_trends_startWallId', id);
      }
    }
    return id;
  }

  /**
   * Update settings
   * @param {*} settings 
   */
  updateSettings(settings) {
    // Update settings with query params if exist
    this.updateSettingsWithQueryParams(settings.data.channels);
    this.setState({ walls: settings.data.channels, settings: settings });
  }


  /**
   * Select a new wall to render
   * @param {*} options 
   */
  selectWall(options) {
    if (this.state.walls.length === 0) { return };

    // Select a wall index, 0 or next wall
    const select = options && options.selectedWallIndex === 0 ? options.selectedWallIndex : this.getSelectDashboard();
    // Get the selected wall
    const wall = this.state.walls[select];
    const rows = options.rows + 1 || wall.stories.matrix.rows;
    const cols = options.cols + 1 || wall.stories.matrix.cols;
    // Compute progression and total
    wall.stories.matrix.tot = rows * cols;
    wall.stories.matrix.rowsPerc = 100 / rows;
    wall.stories.matrix.colsPerc = 100 / cols;
    wall.stories.matrix.rows = rows;
    wall.stories.matrix.cols = cols;

    // A wall is specified
    // Eg after a selection by filter-selector
    if (options.wall) {
      // Use passed metadata
      wall.metadata = options.wall.metadata;
      // Use passed data
      wall.data = options.wall.data;
    }
    console.log('k wall', wall);
    // Activate the selection
    this.setState({ wall: wall, selectedWallIndex: select });
    Tracker.event({
      category: 'UI',
      action: 'selectWall',
      label: this.state.history.location.pathname,
      value: 1
    });
  }

  /**
   * Update walls with query params
   * @param {*} walls 
   */
   updateSettingsWithQueryParams(walls) {
    let storage = JSON.parse(localStorage.getItem('settings'));

    //if (this.state.history.location.search !== ""){
    const params = new URLSearchParams(this.state.history.location.search);

    if (storage) walls[0].stories.matrix.rows = storage.stories.matrix.rows;
    if (storage) walls[0].stories.matrix.cols = storage.stories.matrix.cols;
    let publications;
    if (storage && params.get('publications')) walls[0].publications = JSON.parse(params.get('publications'));

    if (params.get('rows')) walls[0].stories.matrix.rows = params.get('rows');
    if (params.get('cols')) walls[0].stories.matrix.cols = params.get('cols');
    if (params.get('qr.size')) walls[0].qr.size = parseInt(params.get('qr.size'));
    walls[0].qr.enabled = walls[0].qr.enabled = params.get('qr.enabled') === 'true';
    walls[0].menu.matrix.enabled = params.get('matrix') || params.get('menu.matrix.enabled') === 'false' ? false : true;
    walls[0].menu.filter.enabled = params.get('filter') || params.get('menu.filter.enabled') === 'true' ? true : walls[0].menu.filter.enabled;
    walls[0].menu.storiesPreview.enabled = params.get('menu.storiesPreview.enabled') === 'true' ? true : false;
    if (params.get('stories.selection.storyStart')) walls[0].stories.selection.storyStart = params.get('stories.selection.storyStart');
    if (params.get('stories.selection.storyEnd')) walls[0].stories.selection.storyEnd = params.get('stories.selection.storyEnd');
    if (params.get('stories.updater.waiting')) walls[0].stories.updater.waiting = params.get('stories.updater.waiting');
    walls[0].stories.updater.maxParallel = params.get('stories.updater.maxParallel') || 1;
    if (params.get('typers.title.speed')) walls[0].typers.title.speed = params.get('typers.title.speed');
    walls[0].stories.template.type = params.get('stories.template.type') || 'BrickTemplate';
    walls[0].stories.template.settings.image.type = params.get('stories.template.settings.image.type') || 'background';
    walls[0].stories.template.settings.image.effect = params.get('effect') || params.get('stories.template.settings.image.effect') || 'tv';
    walls[0].stories.template.settings.title.verticalAlign = params.get('stories.template.settings.title.verticalAlign') || 'bottom';
    walls[0].stories.template.settings.title.marginLeft = params.get('stories.template.settings.title.marginLeft') || '50%';
    walls[0].stories.template.settings.title.marginRight = params.get('stories.template.settings.title.marginRight') || '0%';
    walls[0].stories.template.settings.title.marginLeftMobile = params.get('stories.template.settings.title.marginLeftMobile') || '10%';
    walls[0].stories.template.settings.title.paddingBottom = params.get('stories.template.settings.title.paddingBottom') || '50px';
    walls[0].stories.template.settings.title.lineHeight = params.get('stories.template.settings.title.lineHeight') || 1.4;
    walls[0].stories.template.settings.dateFormat = params.get('stories.template.settings.dateFormat') || 'timeAgo';
    walls[0].stories.template.settings.fontSize = params.get('stories.template.settings.fontSize');
    if (params.get('stories.reactions.beats.enabled')) walls[0].stories.reactions.beats.enabled = params.get('stories.reactions.beats.enabled') === 'true' ? true : false;
    localStorage.setItem('settings', JSON.stringify(walls[0]));
    //}
  }


  changeWall(wall, index) {
    console.log('changeWall');
    const walls = this.state.walls;

    walls[0].metadata = wall.metadata;
    walls[0].data = wall.data;
    walls[0].id = wall.id;

    // Update walls with query params if exist
    this.updateSettingsWithQueryParams(walls)

    this.setState({ walls: [] });
    setTimeout(() => { //Start the timer
      this.setState({ walls: walls });
      this.selectWall({ repeat: 0 });
      const queryParams = this.state.history.location.search || "";
      this.state.history.push("/" + wall.metadata.type + "/" + wall.metadata.value + queryParams, { some: 'state' });
      this.updateSettingsWithQueryParams(walls);
      Tracker.event({ category: 'UI', action: 'changeWall', label: this.state.history.location.pathname });
      Tracker.pageview("/" + wall.metadata.type + "/" + wall.id + queryParams);
    }, 10)
  }

  searchWall(settings, id) {
    console.log('id', id);
    console.log('settings', settings.data);
    return settings.data.channelsAvaiable.list.filter(wall => wall.id === id);
  }

  changeWallMatrix(rows, cols) {
    const walls = this.state.walls;
    this.selectWall({ repeat: 0, rows: rows, cols: cols });
    // Update walls with query params if exist
    this.setState({ walls: [] });
    setTimeout(() => { //Start the timer
      const currentUrlParams = new URLSearchParams(this.state.history.location.search);
      currentUrlParams.set('rows', rows + 1);
      currentUrlParams.set('cols', cols + 1);
      this.state.history.push(window.location.pathname + "?" + currentUrlParams.toString());
      this.updateSettingsWithQueryParams(walls);

      this.setState({ walls: walls });

    }, 10)


  }

  /**
   * Select a new wall 0 -> 1 -> 2.. -> last -> 0
   */
  getSelectDashboard() {
    // Next wall. First wall if we have only one channel or is the last channel, otherwise go to the next
    return this.state.wall.length === 1 || this.state.walls.length === this.state.selectedWallIndex + 1 ? 0 : this.state.selectedWallIndex + 1;
  }



  render() {

    return (
      <div className="dashboard" >
        {this.state.wall && this.state.walls.map((value, index) => {
          return (
            <div key={index} className={'wall-selected-' + (this.state.selectedWallIndex === index)}>
              {true && this.state.selectedWallIndex === index && this.state.deviceType !== 'mobile' &&
                <Wall key={index} settings={this.state.settings.data} wallSettings={this.state.wall} publications={this.state.publications} dashboard={this}></Wall>
              }
              {this.state.selectedWallIndex === index && this.state.deviceType === 'mobile' &&
                <WallMobile key={index} settings={this.state.settings.data} wallSettings={this.state.wall} dashboard={this}></WallMobile>
              }
            </div>

          )

        })}

        {this.state.settings.data && this.state.wall &&
          <div className="overlay-left-bottom">
            {this.state.wall.menu.matrix.enabled && this.state.deviceType !== 'mobile' &&
              <MatrixSelector walls={this.state.walls} selectedWallIndex={this.state.selectedWallIndex} dashboard={this}></MatrixSelector>
            }
            {false && this.state.wall.menu.filter.enabled &&
              <div className="filters">
                <FilterSelector settings={this.state.settings.data} walls={this.state.walls} selectedWallIndex={this.state.selectedWallIndex} dashboard={this}></FilterSelector>
              </div>
            }
          </div>
        }





      </div>

    )
  }
}
export default Dashboard;







