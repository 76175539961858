
import ReactGA from 'react-ga';
import Settings from './settings';

class Tracker {

  init() {
    ReactGA.initialize(Settings.tracker.google.id, Settings.tracker.google.options);
  }

  event(options) {
    ReactGA.event(options);
  }

  pageview(options) {
    ReactGA.pageview(options);
  }


}

export default new Tracker();