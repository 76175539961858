import React from 'react';
import './progress.css';



class Progress extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        percent: props.percent
      }
    }
    render() {
        const percent = this.props.percent;
        const color = this.props.color;
      return (
        
  
          <div className="progress" style={{width:percent*100 + '%', background:color}}></div>
       
      )
    }
  }
export default Progress;