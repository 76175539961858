import React from 'react';
import '../brick.css';
import './template.css';
import Brick from '../brick';

import Typist from 'react-typist';

import Icon from 'react-icons-kit';
import { shareAlt } from 'react-icons-kit/fa/shareAlt';
import { thumbsOUp } from 'react-icons-kit/fa/thumbsOUp';
import { comments } from 'react-icons-kit/fa/comments';
import { heart } from 'react-icons-kit/fa/heart';
import {ic_multiline_chart} from 'react-icons-kit/md/ic_multiline_chart';

var QRCode = require('qrcode.react');

class BrickTemplate2 extends Brick {
  render() {
    console.log('wall story template', this.state.story);
    let typing = "";
    // Background selection on typewriting
    let background = !this.props.wall.props.wallSettings.typers.changeColorOnTyping.enabled || this.state.story.firstTime === "true" || this.state.style === 'false' ? this.state.story.color.default : this.props.wall.props.wallSettings.typers.changeColorOnTyping.color;
    //const border = !this.props.wall.props.wallSettings.typers.changeColorOnTyping.enabled || this.state.story.firstTime === "true" || this.state.style === 'false' ? this.state.story.color.default : this.props.wall.props.wallSettings.typers.changeColorOnTyping.color;
    let backgroundImage = 'url(\'' + this.state.story.imageUrl + '\') ' + background ;
    console.log(background);
    if (this.state.story.title) {
      typing = <Typist onTypingDone={this.onTypingDoneStory} ><span className="my-custom-class"> {this.state.story.title} </span></Typist>;
    }
    return (

      <div>xxx</div>

    )
  }
}
export default BrickTemplate2;